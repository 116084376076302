import Link from "next/link";

interface NavLinkLogoProps {
  className?: string;
}

const NavLinkLogoWhite: React.FC<NavLinkLogoProps> = ({className}) => {
  return (
    <Link href="/" className={`group ${className || ""}`}>
      <div>
        <div className="logo-mozg-white font-semibold">SCRIPTENCE</div>
      </div>
    </Link>
  );
};

export default NavLinkLogoWhite;
