// app/components/AOSProvider.tsx (Client Component)
"use client";

import {useEffect} from "react";
import "aos/dist/aos.css";

export default function AOSProvider({children}: {children: React.ReactNode}) {
  useEffect(() => {
    const isBot = /bot|crawl|spider/i.test(navigator.userAgent);

    if (typeof window !== "undefined" && !isBot) {
      const handleUserInteraction = () => {
        import("aos").then((AOS) => {
          AOS.init({
            duration: 600,
            offset: 100,
            easing: "ease-in-out",
            once: true
          });
        });

        window.removeEventListener("scroll", handleUserInteraction);
      };

      window.addEventListener("scroll", handleUserInteraction, {once: true});
    }
  }, []);

  return <>{children}</>;
}
